import * as React from 'react'

import { Head } from '../components/MetaInfo'
import { Hero } from '../components/Hero'
import { HeroVideo } from '../components/HeroVideo/HeroVideo'
import { CallToAction } from '../components/CallToAction'
import { VideoBlocks } from '../components/VideoBlocks'
import { Stats } from '../components/Stats'
import { FullImage } from '../components/FullImageSection/FullImage'

const App = () => {
    return (
        <>
            <Head
                title="Bluevishnu"
                description="At SXSW we teamed up with BlueVishnu to turn attendees into interactive avatars, ready to enter the metaverse. Talk about a ‘personal’ introduction to the possibilities of Web3…"
            />
            <main>
                <Hero />
                <HeroVideo />
                <CallToAction title="Bringing SXSW to the multiverse, starting with... you ">
                    <p className="text-black">
                        Since it started in 1987, SXSW has transformed from an
                        indie music festival into a global showcase of the
                        latest from the worlds of art, technology, and culture.
                        With so much going on, it can be challenging for
                        individual brands to break through the noise.
                        <br />
                        <br />
                        Tasked with introducing creators at SXSW to new Web3
                        platforms on behalf of our client, the energy-efficient
                        blockchain Tezos, we decided to do something a little
                        different: We turned attendees into interactive 3D
                        avatars. Talk about a ‘personal’ introduction to the
                        possibilities of Web3...
                    </p>
                </CallToAction>
                <VideoBlocks />
                <FullImage />
                <div className="pb-28 pt-14 lg:pb-extra lg:pt-28 bg-black">
                    <Stats color="text-primary" />
                </div>
            </main>
        </>
    )
}

export default App
