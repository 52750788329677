import React from 'react'
import ReactPlayer from 'react-player'
import * as styles from './styles.module.scss'

const HeroVideo = () => {
    return (
        <div className="h-full relative pb-10 lg:pb-28">
            <div className="bg-black h-2/4 w-full absolute top-0 left-0 z-0"></div>
            <div className="custom-container">
                <div className={styles.video_wr}>
                    <div className={styles.player_wrapper}>
                        <ReactPlayer
                            playsinline={false}
                            background={1}
                            url={
                                'https://player.vimeo.com/video/779738265?h=e0b137a66b&badge=0&autopause=0&player_id=0&app_id=58479/embed'
                            }
                            controls={true}
                            width="100%"
                            height="100%"
                            className={styles.react_player}
                            loop={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export { HeroVideo }
