import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const FullImage = () => {
    return (
        <div className="">
            <StaticImage
                style={{
                    width: '100%',
                    height: '100%',
                }}
                layout="constrained"
                cropFocus="attention"
                alt="Full image"
                transformOptions="fit"
                src={'../../images/full.jpeg'}
                formats={['auto', 'webp', 'avif']}
                className=""
            />
        </div>
    )
}

export { FullImage }
