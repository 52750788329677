import React from 'react'
import ReactPlayer from 'react-player'
import { Fade } from 'react-awesome-reveal'
import * as styles from './styles.module.scss'

const VideoBlocks = () => {
    return (
        <div className="py-14 lg:py-28 bg-strong">
            <div className="custom-container flex flex-col lg:flex-row space-y-10 space-x-0 lg:space-x-28 lg:space-y-0 items-center">
                <div className="w-full lg:w-2/4">
                    <div className={styles.player_wrapper}>
                        <ReactPlayer
                            allowFullScreen
                            playsinline={false}
                            background={1}
                            url={
                                'https://player.vimeo.com/video/779745896?h=e1e57a432f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
                            }
                            controls={true}
                            width="100%"
                            height="100%"
                            className={styles.react_player}
                            loop={false}
                        />
                    </div>
                </div>
                <div className="flex w-full lg:w-2/4">
                    <Fade direction="up" triggerOnce delay={300}>
                        <p className="text-light">
                            In partnership with human avatar platform
                            BlueVishnu, we set up a proprietary scanning booth
                            to capture a 360-degree map of attendees, and
                            transform them into interactive avatars. Once they
                            had been scanned, festival-goers could use their
                            smartphone cameras to place their avatars anywhere
                            inside the festival. As an added bonus, visitors to
                            the Tezos booth were invited to mint their avatars
                            free of charge as unique, collectible NFTs.
                            <br />
                            <br />
                            More than 3,000 3D scans later, we had filled the
                            festival site with virtual attendees, in an
                            activation that captured the imagination of
                            festival-goers and vividly demonstrated the
                            potential of Web3 technology to bridge the physical
                            and digital worlds.
                        </p>
                    </Fade>
                </div>
            </div>
        </div>
    )
}

export { VideoBlocks }
