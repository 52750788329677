import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Fade } from 'react-awesome-reveal'
import * as styles from './styles.module.scss'

const Hero = () => {
    return (
        <div
            className={`${styles.hero} w-full relative bg-black flex flex-row items-start md:items-center justify-center`}
        >
            <div className="flex flex-col-reverse md:flex-row items-center">
                <div className="pb-14 md:pb-0 px-6 w-full md:w-2/4 lg:pl-28">
                    <Fade direction="up" triggerOnce delay={200}>
                        <h1 className="title text-light">
                            At SXSW we teamed up with BlueVishnu to turn
                            attendees into interactive avatars, ready to enter
                            the metaverse
                        </h1>
                    </Fade>
                </div>
                <div className="w-full md:w-2/4 pb-14 md:pb-0">
                    <div className="relative">
                        <StaticImage
                            style={{
                                width: '100%',
                                height: '100%',
                            }}
                            layout="constrained"
                            cropFocus="attention"
                            alt="Hero image"
                            transformOptions="fit"
                            src={'../../images/tezos.png'}
                            formats={['auto', 'webp', 'avif']}
                            className={'static_override'}
                        />
                        <div className={styles.blur}></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { Hero }
